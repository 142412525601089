<template #footer>

  <div class="content-wrapper" style="margin:0px;padding:0px;width: 100%;">

    <span class="float-md-left d-block d-md-inline-block ">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link class="ml-25" href="https://carbonovivo.co" target="_blank">CarbonoVivo</b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

    <span class="float-md-right d-none d-md-block">Hand-crafted &amp; Made with
      <feather-icon icon="HeartIcon" size="21" class="text-danger stroke-current" /> in Colombia
    </span>

  </div>

</template>

<script>
import { BLink, BImg, BRow } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BImg,
    BRow
  },

}
</script>
